<template>
  <div v-if="users && users.length && currentTab === 'agreementers'" class="users-agree box overflow-h">
    <UsersDragItem
      v-for="(element, index) of users"
      :key="element.id"
      :item="element"
      :index="index + 1"
      :isRead="true"
    />
  </div>

  <div v-else-if="users && users.length" class="flex fw ai-c ggap-10">
    <ItemUser v-for="item of users" :key="item.id" :item="item" :isRemove="false" isLink />
  </div>

  <div v-else-if="finalBoss && finalBoss.id" class="flex fw ai-c ggap-10">
    <ItemUser :item="finalBoss" :isRemove="false" isLink />
  </div>

  <BaseAlert v-else type="yellow"> Нет ни одного участника, видимо их никто не добавил! </BaseAlert>
</template>

<script setup>
import { defineProps, toRefs } from 'vue'
import { BaseAlert, ItemUser } from '@/components'
import UsersDragItem from '../UsersDragItem.vue'

// Props
const props = defineProps({
  currentTab: {
    type: String,
    default: 'reviewers'
  },
  users: {
    type: [Object, Array],
    default: () => []
  },
  finalBoss: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { currentTab, users, finalBoss } = toRefs(props)
</script>

<style lang="scss" scoped>
.users-agree {
  &__item {
    text-decoration: none;
    border-bottom: var(--b1);

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background-color: var(--grey-l);
    }
  }
}
</style>
